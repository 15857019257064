import React from 'react'
import AboutUser from './AboutUser'
import Navbar from './Navbar'

const Dashboard = ({ children }) => {
  return (
    <div className="w-full flex bg-gray-100">
      <Navbar />
      <main className="flex-7/10 w-full p-8">
        <AboutUser />
        {children}
      </main>
    </div>
  )
}

export default Dashboard
