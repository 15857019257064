import { useState, useContext, useEffect, useTransition } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";

import Dashboard from "../components/Dashboard";
import { AuthContext } from "../context/authContext";
import portfolioService from "../services/portfolio";
import Select from "../components/atom/Select";
import customerService from "../services/customer";
import SVGIcon from "../components/common/svg";
import PortfolioSelect from "../components/atom/PortfolioSelect";
import competitorService from "../services/competitor";
import facilityService from "../services/facility_profile";
import apiClient from '../utils/apiClient';

const schema = yup.object().shape({
  portfolio_name: yup.string().required("Portfolio Name is required"),
  status: yup
    .string()
    .required("Portfolio Status is required")
    .default("active"),
  pms_type: yup.string().required("PMS is required").default("ssm"),

  username: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "ssm",
    then: () => yup.string().optional(),
  }),
  password: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "ssm",
    then: () => yup.string().optional(),
  }),
  corp_code: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "storedge",
    then: () => yup.string().optional(),
  }),
});

const schemaAddUser = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

const Portfolio = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.getDefault(),
  });

  const {
    control: controlAddUserForm,
    handleSubmit: handleSubmitAddUser,
    reset: resetAddUserForm,
    formState: { errors: errorsAddUserForm },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
    },
    resolver: yupResolver(schemaAddUser),
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const { isAuthenticated, auth } = useContext(AuthContext);
  const isIntegrator = auth?.user?.role?.name === "integrator";
  const [isPending, startTransition] = useTransition();
  const [isPendingAddUser, startTransitionAddUser] = useTransition();
  const [defaultPMSCredentials, setDefaultPMSCredentials] = useState({});

  const [selectedPortfolio, setSelectedPortfolio] = useState();
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const [listUsers, setListUsers] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [nearByStores, setNearByStores] = useState([]);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [isSubmitting, startSubmitting] = useTransition();
  const [radius, setRadius] = useState();
  const [lookupFacility, setLookupFacility] = useState();
  const [isLoaded, setIsLoaded] = useState(true);

  const fetchUsersByPortfolioId = async (portfolio_id) => {
    if (!portfolio_id) {
      alert("Please select a portfolio");
      return;
    }
    startTransitionAddUser(async () => {
      const data = await customerService.getUserByPortfolioId(portfolio_id);
      setListUsers(data);
    });
  };

  const loadFacilities = async (id) => {
    if (!id) return;
    const data = await portfolioService.listFacilities(id);
    setFacilities(data);
  };

  useEffect(() => {
    if (isAuthenticated && isIntegrator && selectedPortfolio?.id) {
      startTransition(async () => {
        await fetchUsersByPortfolioId(selectedPortfolio.id);
      });
    }
  }, [isAuthenticated, isIntegrator, selectedPortfolio?.id]);

  useEffect(() => {
    const loadSelectedPortfolio = async (id) => {
      if (!id) return;
      const data = await portfolioService.getPortfolioById(id);
      if (!data) return;

      const { pms_credentials } = data;
      setDefaultPMSCredentials(pms_credentials);

      setValue("portfolio_name", data.portfolio_name);
      setValue("status", data.status);
      setValue("pms_type", data.pms_type);

      setValue("username", pms_credentials.username);
      setValue("password", pms_credentials.password);
      setValue(
        "corp_code",
        data.pms_type === "storedge"
          ? pms_credentials.company_id
          : pms_credentials.corp_code
      );

      setSelectedPortfolio(data);
      setSelectedPortfolioOption({
        value: data.id,
        label: data.portfolio_name,
      });
    };

    loadFacilities(id);
    loadSelectedPortfolio(id);
  }, [id]);

  const handleChangePortfolioOption = (selected) => {
    setSelectedPortfolioOption(selected);
    if (selected.value) {
      navigate(`/portfolio/${selected.value}`);
    } else {
      navigate("/portfolio");
    }
  };

  const handleSyncFacilities = async () => {
    if (!selectedPortfolioOption) {
      alert("Please select a portfolio")
      return
    }
    setIsLoaded(false);

    try {
      const response = await apiClient.get('/sync-data-ui/' + selectedPortfolioOption.value );
      console.log(response);
      alert("Sync Facilities Success");
      setIsLoaded(true);
    } catch (error) {
      console.error("Error syncing facilities:", error);
      alert("Failed to sync facilities. Please try again.");
      setIsLoaded(true);
    }
  }

  const onSubmitForm = async (data) => {
    if (!selectedPortfolio) {
      alert("Please select a portfolio");
      return;
    }
    const payload = {
      portfolio_name: data.portfolio_name,
      status: data.status,
      pms_type: data.pms_type,
      pms_credentials: {
        ...defaultPMSCredentials,
      },
    };
    if (data.pms_type === "sitelink") {
      payload.pms_credentials.username = data.username;
      payload.pms_credentials.password = data.password;
      payload.pms_credentials.corp_code = data.corp_code;
    }
    if (data.pms_type === "storedge") {
      payload.pms_credentials.company_id = data.corp_code;
    }
    if (data.pms_type === "ssm") {
      payload.pms_credentials.username = data.username;
      payload.pms_credentials.password = data.password;
    }
    await portfolioService.savePortfolioDetail(selectedPortfolio.id, payload);
  };

  const addNewUser = async (data) => {
    if (!selectedPortfolio) {
      alert("Please select a portfolio");
      return;
    }
    try {
      await customerService.createUser({
        first_name: data.fullName.split(" ")?.[0] || "",
        last_name: data.fullName.split(" ")?.[1] || "",
        username: data.email,
        portfolio_id: selectedPortfolio.id,
      });
      await fetchUsersByPortfolioId(selectedPortfolio.id);
      resetAddUserForm({});
      alert("User added successfully");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errors) {
        const errors = error.response.data.errors[0];
        const {
          first_name: firstNameError,
          last_name: lastNameError,
          username: userNameError,
        } = errors || {};
        alert(firstNameError || lastNameError || userNameError);
      }
    }
  };

  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) return;
    await customerService.deleteUser(id);
    await fetchUsersByPortfolioId(selectedPortfolio.id);
  };

  const lookupStorTrack = async (facility) => {
    if (!facility.latitude || !facility.longitude) {
      alert("Facility latitude and longitude is required");
      return;
    }

    setLookupFacility(facility);

    const payload = {
      latitude: facility.latitude,
      longitude: facility.longitude,
      radius: 1,
    };

    document.getElementById(`modal-${facility.id}`).showModal();

    setIsLoadingStores(true);
    const data = await portfolioService.lookupStorTrack(payload);
    setNearByStores(data);
    const store = data.find(
      (store) => store.storeid === facility.comp_stores_info.s_id
    );
    if (store) {
      setSelectedStore({ value: store.storeid, label: store.storename });
      setRadius(facility.comp_stores_info.distance);
    }
    setIsLoadingStores(false);
  };

  const resetFields = () => {
    setRadius(undefined);
    setSelectedStore(null);
    setLookupFacility(null);
  };

  const confirm = async (modalId) => {
    if (!lookupFacility) {
      alert("Mo lookup facility selected");
      return;
    }
    if (!selectedStore) {
      alert("Please select a nearby facility");
      return;
    }
    const storeInfo = nearByStores.find(
      (store) => store.storeid === selectedStore.value
    );
    startSubmitting(async () => {
      await competitorService.updateCompsByStoreId(storeInfo.storeid, {
        ...storeInfo,
        radius,
      });
      await facilityService.updateFacility(lookupFacility.id, {
        stortrack_id: storeInfo.masterid,
        stortrack_radius: radius,
      });
      resetFields();
      loadFacilities(id);
    });
    document.getElementById(modalId).close();
  };

  const onCloseModal = (modalId) => {
    resetFields();
    document.getElementById(modalId).close();
  };

  return (
    <Dashboard>
      <form key={1} onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-8 py-8 bg-white mb-4 flex flex-col gap-8">
          <div className="flex justify-between">
            <PortfolioSelect
              className="w-96"
              value={selectedPortfolioOption}
              onChange={handleChangePortfolioOption}
            />
            <div className="flex gap-4">
              <button
                className=" w-auto border-none bg-green-600 hover:bg-green-700 text-white flex gap-2 items-center"
                onClick={() => navigate("/portfolio/new")}
              >
                <SVGIcon name="plus" />
                <span>Create Portfolio</span>
              </button>
              {isIntegrator && <button
                className="px-8 rounded rounded-lg bg-red-500 text-white font-normal text-base"
                onClick={() => handleSyncFacilities()}
                type="button"
              >
                {isLoaded ? "Sync Facilities" : "Syncing..."}
              </button>}
            </div>
          </div>

          <hr />

          {selectedPortfolio && (
            <>
              <h2 className="text-2xl font-semibold text-gray-800">
                Update Portfolio
              </h2>
              <div className="flex flex-col gap-4">
                <label htmlFor="portfolio_name">Name</label>
                <Controller
                  control={control}
                  name="portfolio_name"
                  render={({ field }) => (
                    <input {...field} className="border border-gray-700" />
                  )}
                />
                {errors.portfolio_name && (
                  <p className="text-red-500">
                    {errors.portfolio_name.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="status">Status</label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        className="w-full border border-gray-700"
                        selected={field.value}
                        options={["active", "disabled"]}
                        label={{
                          active: "Active",
                          disabled: "Disabled",
                        }}
                      />
                    );
                  }}
                />
                {errors.status && (
                  <p className="text-red-500">{errors.status.message}</p>
                )}
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="pms_type">PMS</label>
                <Controller
                  name="pms_type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        className="w-full border border-gray-700"
                        selected={field.value}
                        options={["storedge", "sitelink", "ssm"]}
                        label={{
                          storedge: "storEDGE",
                          sitelink: "SiteLink",
                          ssm: "SSM",
                        }}
                      />
                    );
                  }}
                />
                {errors.pms_type && (
                  <p className="text-red-500">{errors.pms_type.message}</p>
                )}
              </div>
              {(watch("pms_type") === "sitelink" ||
                watch("pms_type") === "ssm") && (
                  <div className="flex flex-col gap-4">
                    <label htmlFor="username">Username</label>
                    <Controller
                      control={control}
                      name="username"
                      render={({ field }) => <input {...field} />}
                    />
                    {errors.username && (
                      <p className="text-red-500">{errors.username.message}</p>
                    )}
                  </div>
                )}
              {(watch("pms_type") === "sitelink" ||
                watch("pms_type") === "ssm") && (
                  <div className="flex flex-col gap-4">
                    <label htmlFor="password">Password</label>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => <input {...field} />}
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password.message}</p>
                    )}
                  </div>
                )}
              {(watch("pms_type") === "sitelink" ||
                watch("pms_type") === "storedge") && (
                  <div className="flex flex-col gap-4">
                    <label htmlFor="corp_code">Corp Code</label>
                    <Controller
                      control={control}
                      name="corp_code"
                      render={({ field }) => <input {...field} />}
                    />
                    {errors.corp_code && (
                      <p className="text-red-500">{errors.corp_code.message}</p>
                    )}
                  </div>
                )}
              <div className="self-end">
                <button
                  type="submit"
                  className="px-4 px-2 text-primary border-2 border-red-500"
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </form>

      {selectedPortfolio && (
        <>
          <form key={2} onSubmit={handleSubmitAddUser(addNewUser)} noValidate>
            <div className="w-full rounded-2xl border-2 border-gray-200 px-8 py-8 bg-white mb-4 flex flex-col gap-8">
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">Users</h2>
                <div className="py-4 flex flex-col gap-16">
                  <table className="border table table-zebra table-lg">
                    <thead>
                      <tr>
                        <th className="text-left">Full Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-lef">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isPendingAddUser && (
                        <tr>
                          <td colSpan={3} className="text-center">
                            <span className="loading loading-spinner text-primary loading-md"></span>
                          </td>
                        </tr>
                      )}
                      {!isPendingAddUser &&
                        listUsers.map((user, index) => (
                          <tr key={user.id}>
                            <td className="text-left">{`${user.first_name} ${user.last_name}`}</td>
                            <td className="text-left">{user.email}</td>
                            <td className="text-left">
                              <button
                                type="button"
                                onClick={() => handleDeleteUser(user.id)}
                                className="border-none bg-red-600 hover:bg-red-700 text-white flex items-center space-x-2 p-2"
                              >
                                <SVGIcon name="delete" />
                                <span>DELETE</span>
                              </button>
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <td>
                          <div>
                            <Controller
                              name="fullName"
                              control={controlAddUserForm}
                              rules={{ required: "Full Name is required" }}
                              render={({
                                field: { name, onChange, value },
                              }) => (
                                <input
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  type="text"
                                  className={`min-w-60 mr-10 border ${errorsAddUserForm.fullName
                                      ? "border-red-700"
                                      : "border-gray-700"
                                    }`}
                                  placeholder="Full Name"
                                  autoComplete="name"
                                />
                              )}
                            />
                            {errorsAddUserForm.fullName && (
                              <div className="text-red-700">
                                {errorsAddUserForm.fullName.message}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Controller
                              name="email"
                              control={controlAddUserForm}
                              render={({
                                field: { name, onChange, value },
                              }) => (
                                <input
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  type="email"
                                  className={`min-w-72 mr-[40px] border ${errorsAddUserForm.email
                                      ? "border-red-700"
                                      : "border-gray-700"
                                    }`}
                                  placeholder="Email"
                                  autoComplete="email"
                                />
                              )}
                            />
                            {errorsAddUserForm.email && (
                              <div className="text-red-700">
                                {errorsAddUserForm.email.message}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <button
                            type="submit"
                            className=" w-auto border-none bg-green-600 hover:bg-green-700 text-white flex gap-2 items-center"
                          >
                            {isPendingAddUser ? (
                              <span className="loading loading-spinner"></span>
                            ) : (
                              <>
                                <SVGIcon name="plus" />
                                <span>ADD NEW USER</span>
                              </>
                            )}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
          <div className="w-full rounded-2xl border-2 border-gray-200 p-8 bg-white mb-4 flex flex-col gap-8">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800">
                StorTrack
              </h2>
              <div className="py-4 flex flex-col gap-16 overflow-x-auto">
                <table className="border table table-zebra table-lg">
                  <thead>
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Facility Name</th>
                      <th className="text-left">StorTrack store name</th>
                      <th className="text-left">Competitor Radius</th>
                      <th className="text-left">Store Lookup</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facilities.map((facility, index) => (
                      <tr key={facility.id}>
                        <td className="text-left">{index + 1}</td>
                        <td className="text-left">
                          <div className="flex flex-row items-center">
                            {facility.facility_name}
                          </div>
                        </td>
                        <td className="text-left">
                          <div className="flex flex-row items-center">
                            {facility.comp_stores_info.store_name}
                          </div>
                        </td>
                        <td className="text-left">
                          <div className="flex flex-row items-center">
                            {facility.comp_stores_info.distance}
                          </div>
                        </td>
                        <td className="text-left">
                          <button
                            type="button"
                            title="Lookup StorTrack"
                            className="w-6 h-6 relative -top-2"
                            onClick={() => lookupStorTrack(facility)}
                          >
                            <SVGIcon name="search" />
                          </button>
                          <dialog id={`modal-${facility.id}`} className="modal">
                            <div
                              className="modal-box"
                              style={{
                                maxHeight: 650,
                                height: isSelectMenuOpen ? "100%" : 400,
                              }}
                            >
                              <div>
                                <button
                                  className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 outline-none"
                                  onClick={() =>
                                    onCloseModal(`modal-${facility.id}`)
                                  }
                                >
                                  ✕
                                </button>

                                <h3 className="flex font-bold text-lg">
                                  StorTrack settings for{" "}
                                  {lookupFacility?.facility_name}
                                </h3>

                                <div className="h-full">
                                  <>
                                    <label className="form-control w-full">
                                      <div className="label mt-8">
                                        <span className="label-text">
                                          StorTrack store
                                        </span>
                                      </div>
                                      {isLoadingStores ? (
                                        <span className="loading loading-spinner loading-md"></span>
                                      ) : (
                                        <ReactSelect
                                          placeholder="Select store"
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              height: 46,
                                              minHeight: 46,
                                              borderRadius: 8,
                                              width: "100%",
                                              border: "1px solid #d1d5db",
                                            }),
                                          }}
                                          options={nearByStores.map(
                                            (store) => ({
                                              value: store.storeid,
                                              label: store.storename,
                                            })
                                          )}
                                          menuIsOpen={isSelectMenuOpen}
                                          onMenuOpen={(open) => {
                                            setIsSelectMenuOpen(true);
                                          }}
                                          onMenuClose={() =>
                                            setIsSelectMenuOpen(false)
                                          }
                                          value={selectedStore}
                                          onChange={(selected) => {
                                            setSelectedStore(selected);
                                            const facility = facilities.find(item => item.comp_stores_info.s_id === selected.value);
                                            setRadius(facility?.comp_stores_info?.distance || false);
                                          }}
                                          maxMenuHeight={350}
                                        />
                                      )}
                                    </label>
                                    <label className="form-control w-full">
                                      <div className="label">
                                        <span className="label-text">
                                          StorTrack Radius for Competitors
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        min={0}
                                        placeholder="Input radius"
                                        className="input input-bordered w-full"
                                        value={radius}
                                        onChange={(e) =>
                                          setRadius(e.target.value)
                                        }
                                      />
                                    </label>
                                  </>
                                </div>

                                <div className="flex flex-row items-center justify-between absolute left-0 bottom-2 w-full p-4 px-6">
                                  <button
                                    className=" text-primary border-2 border-red-500"
                                    onClick={() =>
                                      confirm(`modal-${facility.id}`)
                                    }
                                  >
                                    {isSubmitting ? (
                                      <span className="loading loading-spinner loading-xs"></span>
                                    ) : (
                                      "Confirm"
                                    )}
                                  </button>

                                  <button
                                    className="btn outline-none"
                                    onClick={() =>
                                      onCloseModal(`modal-${facility.id}`)
                                    }
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </dialog>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
};

export default Portfolio;
