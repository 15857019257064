
import { useState } from 'react'
import moment from 'moment'
import dayjs from "dayjs";
import CurrencyInput from 'react-currency-input-field'
import { formatDollarWithSymbol, formatDollar } from '../utils/formats'

const SORT_FIELDS = {
  tenant_fname: 'tenant_fname',
  unit_no: 'unit_no',
  current_rate: 'current_rate',
  std_rate: 'std_rate',
  std_rate_delta: 'std_rate_delta',
  move_out_probability: 'move_out_probability',
  previously_increased: 'previously_increased',
  last_ecri_date: 'last_ecri_date',
  increase_amount: 'increase_amount',
  increase_percentage: 'increase_percentage',
}

const CustomerDetails = ({ rows, handleChange, handleExcludeChange }) => {
  const [updatedTenants, setUpdateTenants] = useState(rows);

  const originRows = [...rows];
  const [currentFilteredTenants, setCurrentFilteredTenants] = useState(rows);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleNewRateChange = (tenant, value) => {
    tenant.new_rate = value
    handleChange(tenant, value)
  }

  const showCurrentRateColor = (tenant) => {
    if (tenant.current_rate === tenant.std_rate) {
      return '';
    }

    return tenant.current_rate > tenant.std_rate ? '#2F8046' : '#D80E0E';
  }

  const handleChangeExclude = (tenant, index, value) => {
    updatedTenants[index].exclude_submit = value;
    setUpdateTenants([...updatedTenants])

    tenant.exclude_submit = value;
    handleExcludeChange(tenant, value);
  }

  const sortTenants = (tenants, key, direction) => {
    if (key === SORT_FIELDS.unit_no) {
      if (direction === 'desc') {
        return [...originRows].reverse();
      } else {
        return [...originRows]; // return clone
      }
    }

    // Have tenants filter by tenant.current_rate - tenant.std_rate
    if (key === SORT_FIELDS.std_rate_delta) {
      if (direction === 'desc') {
        return [...originRows].sort((a, b) => (b.current_rate - b.std_rate) - (a.current_rate - a.std_rate));
      } else {
        return [...originRows].sort((a, b) => (a.current_rate - a.std_rate) - (b.current_rate - b.std_rate));
      }
    }

    if (key === SORT_FIELDS.previously_increased) {
      if (direction === 'desc') {
        return [...originRows].reverse();
      } else {
        return [...originRows]; // return clone
      }
    }

    return [...tenants].sort((a, b) => {
      if (a[key] === null && b[key] === null) {
        return 0;
      }
      if (a[key] === null) {
        return 1;
      }
      if (b[key] === null) {
        return -1;
      }

      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key, cus_direction = '') => {
    let direction = cus_direction || 'asc';
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }

    const sortedTenants = !!direction ? sortTenants(updatedTenants, key, direction) : currentFilteredTenants
    setUpdateTenants(sortedTenants);
    setSortConfig({ key, direction });
  }

  const renderSortIcon = (key) => {
    const className = sortConfig.key !== key ? '' :
      sortConfig.direction === 'asc' ? '-up' :
        sortConfig.direction === 'desc' ? '-down' : '';

    return (
      <img src={`/assets/images/sort${className}.svg`} alt={`Sort Icon${className}`} className="w-[1rem] p-1 inline" />
    )
  }

  return (
    <table className="w-full table-tenant">
      <thead className="text-gray-600">
        <tr className="uppercase text-left">
          <th onClick={() => handleSort(SORT_FIELDS.tenant_fname)}>Tenant Name/Unit &nbsp;
            {renderSortIcon(SORT_FIELDS.tenant_fname)}</th>
          <th onClick={() => handleSort(SORT_FIELDS.unit_no)}>Unit Type &nbsp;
            {renderSortIcon(SORT_FIELDS.unit_no)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.current_rate)}>
            Current
            <br />
            Tenant Rate&nbsp;
            {renderSortIcon(SORT_FIELDS.current_rate)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.std_rate)}>
            Today's
            <br />
            Street Rate&nbsp;
            {renderSortIcon(SORT_FIELDS.std_rate)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.std_rate_delta)}>
            Street
            <br />
            Rate Delta&nbsp;
            {renderSortIcon(SORT_FIELDS.std_rate_delta)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.move_out_probability)}>
            Move-Out
            <br />
            Probability&nbsp;
            {renderSortIcon(SORT_FIELDS.move_out_probability)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.previously_increased)}>
            Previously <br /> Increased&nbsp;
            {renderSortIcon(SORT_FIELDS.previously_increased)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.last_ecri_date)}>
            Last
            <br />
            Increase
            <br />
            Date&nbsp;
            {renderSortIcon(SORT_FIELDS.last_ecri_date)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.increase_amount)}>
            Rate
            <br />
            Increase $&nbsp;
            {renderSortIcon(SORT_FIELDS.increase_amount)}
          </th>
          <th onClick={() => handleSort(SORT_FIELDS.increase_percentage)}>
            Rate <br /> Increase  %&nbsp;
            {renderSortIcon(SORT_FIELDS.increase_percentage)}
          </th>
          <th>Effective <br /> Date</th>
          <th>New Rate</th>
          <th>Exclude</th>
        </tr>
      </thead>
      <tbody>
        {updatedTenants &&
          updatedTenants.map((tenant, index) => (
            <tr key={index}>
              <td style={{ maxWidth: 80 }}>
                {tenant.tenant_fname} {tenant.tenant_lname}
                <br />
                {tenant.unit_no}
              </td>
              <td>{tenant.unit_type}</td>
              <td style={{ color: showCurrentRateColor(tenant) }}>${formatDollar(tenant.current_rate)}</td>
              <td>${formatDollar(tenant.std_rate)}</td>
              <td>{formatDollarWithSymbol(tenant.current_rate - tenant.std_rate)}</td>
              <td>
                <span className="px-5 py-2 bg-green-200 rounded-lg">{(tenant.move_out_probability * 100.0).toFixed(1)}%</span>
              </td>
              <td>{dayjs(tenant.move_in_date).isBefore(dayjs(tenant.last_ecri_date)) ? "Yes" : "No"}</td>
              <td>{moment(tenant.last_ecri_date).format('M/D/YY')}</td>
              <td>${formatDollar(tenant.increase_amount)}</td>
              <td>{(tenant.increase_percentage * 100.0).toFixed(2)}%</td>
              <td>{tenant.effective_date}</td>
              <td className="w-24">
                {
                  tenant.exclude_submit ? (
                    <span style={{ marginLeft: 16 }}>$-</span>
                  ) : <CurrencyInput
                    style={{ width: '90px' }}
                    prefix="$"
                    disableGroupSeparators={true}
                    defaultValue={formatDollar(tenant.new_rate)}
                    decimalsLimit={2}
                    onValueChange={(value) => handleNewRateChange(tenant, value)}
                  />
                }
              </td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={tenant.exclude_submit}
                    onChange={(e) => handleChangeExclude(tenant, index, e.target.checked)}
                  />
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default CustomerDetails
