import { useTransition } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import Select from "../components/atom/Select";
import Dashboard from "../components/Dashboard";
import portfolioService from "../services/portfolio";
import { useNavigate } from "react-router-dom";
import SVGIcon from "../components/common/svg";

const schema = yup.object().shape({
  portfolio_name: yup.string().required("Portfolio Name is required"),
  status: yup
    .string()
    .required("Portfolio Status is required")
    .default("active"),
  pms_type: yup.string().required("PMS is required").default("storedge"),

  username: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "ssm",
    then: () => yup.string().optional(),
  }),
  password: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "ssm",
    then: () => yup.string().optional(),
  }),
  corp_code: yup.string().when("pms_type", {
    is: (value) => value === "sitelink" || value === "storedge",
    then: () => yup.string().optional(),
  }),
  users: yup.array().of(
    yup.object().shape({
      fullName: yup
        .string()
        .required("Full Name is required")
        .test(
          "has-first-and-last-name",
          "Full Name must include both first and last name",
          (value) => value && value.trim().split(" ").length >= 2
        ),
      email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    })
  ),
});

const CreatePortfolio = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.getDefault(),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  const [isPending, startTransition] = useTransition();

  const navigate = useNavigate();

  const onSubmitForm = async (data) => {
    const payload = {
      portfolio_name: data.portfolio_name,
      status: data.status,
      pms_type: data.pms_type,
      users: data.users,
      pms_credentials: {},
    };
    if (data.pms_type === "sitelink") {
      payload.pms_credentials.username = data.username;
      payload.pms_credentials.password = data.password;
      payload.pms_credentials.corp_code = data.corp_code;
    }
    if (data.pms_type === "storedge") {
      payload.pms_credentials.company_id = data.corp_code;
    }
    if (data.pms_type === "ssm") {
      payload.pms_credentials.username = data.username;
      payload.pms_credentials.password = data.password;
    }
    startTransition(async () => {
      const result = await portfolioService.createPortfolioAndUsers(payload);
      if (result) {
        reset({
          portfolio_name: "",
          status: "active",
          pms_type: "storedge",
          username: "",
          password: "",
          corp_code: "",
        });
      }
    });
  };

  return (
    <Dashboard>
      <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-8 py-8 bg-white mb-4 flex flex-col gap-8">
          <button
            className="items-center flex gap-2"
            onClick={() => {
              navigate(-1);
            }}
          >
          <span class="material-symbols-outlined">
            arrow_back
          </span>
            Back
          </button>

          <h2 className="text-2xl font-semibold text-gray-800">
            Create Portfolio
          </h2>
          <div className="flex flex-col gap-4">
            <label htmlFor="portfolio_name">Name</label>
            <Controller
              control={control}
              name="portfolio_name"
              render={({ field }) => (
                <input {...field} className="border border-gray-700" />
              )}
            />
            {errors.portfolio_name && (
              <p className="text-red-500">{errors.portfolio_name.message}</p>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor="status">Status</label>
            <Controller
              name="status"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    className="w-full border border-gray-700"
                    selected={field.value}
                    options={["active", "disabled"]}
                    label={{
                      active: "Active",
                      disabled: "Disabled",
                    }}
                  />
                );
              }}
            />
            {errors.status && (
              <p className="text-red-500">{errors.status.message}</p>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor="pms_type">PMS</label>
            <Controller
              name="pms_type"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    className="w-full border border-gray-700"
                    selected={field.value}
                    options={["storedge", "sitelink", "ssm"]}
                    label={{
                      storedge: "storEDGE",
                      sitelink: "SiteLink",
                      ssm: "SSM",
                    }}
                  />
                );
              }}
            />
            {errors.pms_type && (
              <p className="text-red-500">{errors.pms_type.message}</p>
            )}
          </div>
          {(watch("pms_type") === "sitelink" ||
            watch("pms_type") === "ssm") && (
            <div className="flex flex-col gap-4">
              <label htmlFor="username">Username</label>
              <Controller
                control={control}
                name="username"
                render={({ field }) => <input {...field} />}
              />
              {errors.username && (
                <p className="text-red-500">{errors.username.message}</p>
              )}
            </div>
          )}
          {(watch("pms_type") === "sitelink" ||
            watch("pms_type") === "ssm") && (
            <div className="flex flex-col gap-4">
              <label htmlFor="password">Password</label>
              <Controller
                control={control}
                name="password"
                render={({ field }) => <input {...field} />}
              />
              {errors.password && (
                <p className="text-red-500">{errors.password.message}</p>
              )}
            </div>
          )}
          {(watch("pms_type") === "sitelink" ||
            watch("pms_type") === "storedge") && (
            <div className="flex flex-col gap-4">
              <label htmlFor="corp_code">Corp Code</label>
              <Controller
                control={control}
                name="corp_code"
                render={({ field }) => <input {...field} />}
              />
              {errors.corp_code && (
                <p className="text-red-500">{errors.corp_code.message}</p>
              )}
            </div>
          )}
          <hr />
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">
              Create Users
            </h2>
            <div className="py-4 flex flex-col gap-16 overflow-x-auto">
              <table className="table table-zebra table-lg">
                <thead>
                  <tr>
                    <th className="text-left">Full Name</th>
                    <th className="text-left">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {isPending && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        <span className="loading loading-spinner text-primary loading-md"></span>
                      </td>
                    </tr>
                  )}

                  {fields.map((field, index) => (
                    <tr key={field.id}>
                      <td>
                        <div>
                          <Controller
                            name={`users[${index}].fullName`}
                            control={control}
                            rules={{ required: "Full Name is required" }}
                            render={({ field: { name, onChange, value } }) => (
                              <input
                                name={name}
                                value={value}
                                onChange={onChange}
                                type="text"
                                className={`min-w-60 mr-10 border ${
                                  errors?.users?.[index]?.fullName
                                    ? "border-red-700"
                                    : "border-gray-700"
                                }`}
                                placeholder="Full Name"
                                autoComplete="name"
                              />
                            )}
                          />
                          {errors?.users?.[index]?.fullName && (
                            <div className="text-red-700">
                              {errors.users[index].fullName.message}
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          <Controller
                            name={`users[${index}].email`}
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                              <input
                                name={name}
                                value={value}
                                onChange={onChange}
                                type="email"
                                className={`min-w-72 mr-[40px] border ${
                                  errors?.users?.[index]?.email
                                    ? "border-red-700"
                                    : "border-gray-700"
                                }`}
                                placeholder="Email"
                                autoComplete="email"
                              />
                            )}
                          />
                          {errors?.users?.[index]?.email && (
                            <div className="text-red-700">
                              {errors.users[index].email.message}
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          title="Remove User"
                          onClick={() => remove(index)}
                          className="w-auto min-h-10 border-none bg-red-600 hover:bg-red-700 text-white flex gap-2 items-center"
                        >
                          <SVGIcon name="delete" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="3">
                      <button
                        type="button"
                        onClick={() => append({ fullName: "", email: "" })}
                        className="w-auto border-none bg-green-600 hover:bg-green-700 text-white flex gap-2 items-center"
                      >
                        {isPending ? (
                          <span className="loading loading-spinner"></span>
                        ) : (
                          <>
                            <SVGIcon name="plus" />
                            <span>ADD USER</span>
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="self-end">
            <button
              type="submit"
              disabled={isPending}
              className="px-4 px-2 text-primary border-2 border-red-500"
            >
              {isPending ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </Dashboard>
  );
};

export default CreatePortfolio;
